import React, { useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "./Pages/Home/Home";
import AboutUs from "./Pages/AboutUs/Index.jsx";
import StatsAndExpense from "./Pages/StatsAndExpenses/Index";
import DepartmentsAndFacilities from "./Pages/Departments&Facilities/Index";
import OngoingCampaign from "./Pages/OngoingCampaign/Index";
import BoardOfManagement from "./Pages/BoardOfManagement/Index";
import Login from "./Pages/Login/Index";
import PrivacyPolicy from "./Pages/PrivacyPolicy/Index";
import RefundPolicy from "./Pages/RefundPolicy/Index";
import TermsAndCondition from "./Pages/TermsAndCondition/Index";
import ContactUs from "./Pages/ContactUs/Index";
import AdminRoutes from "./Pages/AdminRoutes/Index";
import AuthState from "./context/authContext/AuthState";
import PublicRoutes from "./utils/PublicRoutes";
import PrivateRoutes from "./utils/PrivateRoutes";
import CampaignByCategory from "./Pages/CampaignByCategory/Index";
import CampaignDetails from "./Pages/CampaignDetails/Index";
import Donate from "./Pages/Donate/Index";
import PageDoesNotExists from "./Pages/PageDoesNotExists/NotFoundPage";

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <AuthState>
      <div className='main-container'>
        <ScrollToTop />
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<PublicRoutes><Home /></PublicRoutes>} />
          <Route path="/about/*" element={<PublicRoutes><AboutUs /></PublicRoutes>}>
            <Route path="Overview" element={<AboutUs />} />
            <Route path="Departments&Facilities" element={<DepartmentsAndFacilities />} />
            <Route path="Board-of-Management" element={<BoardOfManagement />} />
          </Route>
          <Route path="/account/*" element={<PublicRoutes><StatsAndExpense /></PublicRoutes>}>
            <Route path="Stats-And-Expense" element={<StatsAndExpense />} />
            <Route path="Ongoing-Campaigns" element={<OngoingCampaign />} />
          </Route>
          <Route path="/Login" element={<PublicRoutes restricted><Login /></PublicRoutes>} />
          <Route path="/Privacy-Policy" element={<PublicRoutes><PrivacyPolicy /></PublicRoutes>} />
          <Route path="/Refund-Policy" element={<PublicRoutes><RefundPolicy /></PublicRoutes>} />
          <Route path="/TermsAndCondition-Policy" element={<PublicRoutes><TermsAndCondition /></PublicRoutes>} />
          <Route path="/Contact-Us" element={<PublicRoutes><ContactUs /></PublicRoutes>} />
          <Route path="/Campaign-By-Category/:title" element={<PublicRoutes><CampaignByCategory /></PublicRoutes>} />
          <Route path="/Campaign-Details/:id" element={<PublicRoutes><CampaignDetails /></PublicRoutes>} />
          <Route path="/Donate/:id" element={<PublicRoutes><Donate /></PublicRoutes>} />

          {/* Private Routes */}
          <Route path="/Admin-Panel/*" element={<PrivateRoutes><AdminRoutes /></PrivateRoutes>} />

          {/* Catch-all route */}
          <Route path="*" element={<PageDoesNotExists />} />
        </Routes>
      </div>
    </AuthState>
  );
}

export default App;